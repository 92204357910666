<template>
  <div class="exchangeLuckyStars">
    <Star-Top></Star-Top>
    <div class="container">
      <div class="section-one">
        <div>
          <div class="lable1">{{$t('menu.round')}}：{{lotteryId}}<i class="ml5">|</i><span>{{$t('menu.drawAPrize')}} {{formatTimeToType1(viewLottery.endTime)}}</span></div>
          <div class="lable2">
            <span> {{count}}</span>
            <i v-if="seconds == 0">{{$t('menu.inTheLottery')}}</i> 
            <i v-if="seconds > 0">{{$t('menu.upcomingLottery')}}</i> 
          </div>
        </div>
        <div class="right"><router-link :to="{name:'LuckyStarGameplay',path:'/luckyStarGameplay'}"><img src="../../../../../public/images/lukyStar/wanfa.png" alt=""></router-link></div>
      </div>
      <div class="section-two">
        <div class="title-name">{{$t('menu.prizePool')}}</div>
        <!--币数量*币价格 -->
        <div class="money-num">${{conversionToUsdt(viewLottery.amountCollectedInCake)}}</div> 
        <div class="type-choose" @click="chooseCurrency">
          <i>{{viewLottery.amountCollectedInCake}}</i>
          <span v-if="currentCurrency==0">STAR</span>
          <span v-if="currentCurrency==1">ZEED</span>
          <span v-if="currentCurrency==2">YEED</span>
          <img src="../../../../../public/images/lukyStar/icon-down.png" alt="">
        </div>
      </div>
      <div class="section-three">
        <div class="tip">{{$t('menu.luckyStarsThisRound')}}<span>{{currentLotteryUserHasTicketsNum}}</span></div>
        <div class="label-btn">
          <button v-if="seconds == 0" disabled="!exchangeStarDis"><img src="../../../../../public/images/lukyStar/duihuan_jin.png" alt=""></button>
          <button v-if="seconds > 0" disabled="exchangeStarDis" @click="exchangeStar"><img src="../../../../../public/images/lukyStar/dhxyx.png" alt=""></button>
        </div>
      </div>
      <div class="section-four">
        <div class="desc">
          <div>{{$t('menu.bonusShareNumber')}}</div>
          <div class="mt5">{{$t('menu.currentWinnableBonuses')}}</div>
        </div>
        <div class="desc-content">
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop1')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[0]}}</span> {{contract}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[0])}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop2')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[1]}}</span> {{contract}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[1])}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop3')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[2]}}</span> {{contract}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[2])}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop4')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[3]}}</span> {{contract}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[3])}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop5')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[4]}}</span> {{contract}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[4])}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop6')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[5]}}</span> {{contract}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[5])}}</div>
          </div>
          <div class="list">
            <div class="title red">{{$t('menu.incinerate')}}</div>
            <div class="num"><span>{{viewLottery.treasuryAmount}}</span> {{contract}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.treasuryAmount)}}</div>
          </div>
        </div>
      </div>
    </div>
    <Exchange-Dialog v-if="isShowDialog" :isType="isTypeChoosed" :currencyType="currentCurrency" @transfer="handleTypeData"></Exchange-Dialog>
  </div> 
</template>

<script>
import '../styles/ExchangeLuckyStars.css';
import '../styles/common.css';
import StarTop from '../common/StarTop.vue';
import ExchangeDialog from '../Dialog/ExchangeDialog.vue'

export default {
  name: 'ExchangeLuckyStars',
  components:{StarTop,ExchangeDialog},
  data(){
    return{
      isTypeChoosed:'0', //0选择币种  1点击申购
      isShowDialog:false,
      currentCurrency:0, //0是STAR 1是ZEED 2是YEED

      contract:'STAR',// 合约 STAR ZEED YEED
      lotteryId:'',//
      priceTicketInCake:0,//
      // 根据lotteryId获得整一局的信息
      viewLottery:{
        status: "0", // Pending没开始 1，Open开始 2，Close停止投注 3，Claimable可以领奖 4
        startTime: "0", //开始时间
        endTime: "0", // 结束投注时间
        priceTicketInCake: "0", // 一张彩票的价格，对应star、zeed、yeed
        discountDivisor: "0",// 这个变量不用理
        rewardsBreakdown: [], //每个挡位的奖励比例， x/10000
        treasuryFee: "0", // 打入黑洞的比例，x/10000
        cakePerBracket: [], // 开奖后，每个挡位中奖币数量
        countWinnersPerBracket: [], // 开奖后，每个挡位中奖的用户数
        firstTicketId: "0",
        firstTicketIdNextLottery: "0",
        amountCollectedInCakePre: "0", // 上轮剩下来的币
        amountCollectedInCake: "0", // 本轮奖池的币
        finalNumber: "0", // 开奖号码
        rewardsBreakdownAmount:[],
        treasuryAmount:0,
      },
      formatTimeToType1:this.mLottery.formatTimeToType1,
      price:0,//
      starPrice:0,//
      zeedPrice:0,//
      yeedPrice:0,//
      currentLotteryUserHasTicketsNum:0,//本回合当前用户有多少彩票
      count: '', //倒计时
      seconds: 0, // 倒计时秒数
      // statusText:['没开始','开始','停止投注','可以领奖']
      exchangeStarDis:false,//申购幸运星按钮禁用状态
    }
  },
  created:function(){
   
    let that = this
    // 获取Star价格
    this.mLottery.getStarAddrPrice().then(starPrice=>{
      that.starPrice = starPrice
      that.price = starPrice
    })
    // 获取Zeed价格
    this.mLottery.getZeedAddrrPrice().then(zeedPrice=>{
      that.zeedPrice = zeedPrice
    })
    // 获取Yeed价格
    this.mLottery.getYeedAddrPrice().then(yeedPrice=>{
      that.yeedPrice = yeedPrice
    })
    this.switchCurrType(this.currentCurrency)

  },
  mounted() {
    this.Time() //调用定时器
  },
  methods:{
    chooseCurrency(){
      this.isShowDialog = true;
      this.isTypeChoosed = '0';
    },
    exchangeStar(){
      if(this.seconds ==0){
        this.$toast(this.$t('menu.notStarted'));
        return false;
      }
      this.isShowDialog = true;
      this.isTypeChoosed = '1';
    },
    handleTypeData(type,isClose,currType){
      if(type=='0'){//此判断里进行操作-选择币种底部那个弹窗
        this.currentCurrency = currType;//选择后赋值
        this.switchCurrType(currType)
      }
      if(isClose==0){//此判断里进行关闭弹窗的操作
        this.isShowDialog = false;
      }else{
        this.isShowDialog = true;
      }
    },
    // 切换类型 0是STAR 1是ZEED 2是YEED
    switchCurrType(currType){
      switch (currType) {
          case 0:
            this.contract = 'STAR'
            this.price = this.starPrice
            break;
          case 1:
            this.contract = 'ZEED'
            this.price = this.zeedPrice
            break;
          case 2:
            this.contract = 'YEED'
            this.price = this.yeedPrice
            break;
        }
        // 切换彩票合约
        this.mLottery.switchContract(currType)
        this.createdGetData()
    },
    createdGetData(){
      let that = this
      // 创建时获取数据
      console.log(this.mLottery)
      // 当前正在进行的lotteryId
      this.mLottery.viewCurrentLotteryId().then(lotteryId=>{
        console.log(lotteryId);
        that.lotteryId = lotteryId;
        // 根据lotteryId获得整一局的信息
        this.mLottery.viewLottery(lotteryId).then(viewLotteryRes=>{
          that.priceTicketInCake = this.mLottery.amountConversion(viewLotteryRes.priceTicketInCake);
          let viewLottery = Object.assign({}, that.viewLottery, viewLotteryRes);
          viewLottery.amountCollectedInCake = this.mLottery.amountConversion(viewLottery.amountCollectedInCake);
          let rewardsBreakdownAmount = [];
          for (let index = 0; index < viewLottery.rewardsBreakdown.length; index++) {
            rewardsBreakdownAmount[index] = Math.floor((viewLottery.amountCollectedInCake * viewLottery.rewardsBreakdown[index] /10000) * 1000000) / 1000000; 
          }
          viewLottery.rewardsBreakdownAmount = rewardsBreakdownAmount;
          viewLottery.treasuryAmount =  Math.floor((viewLottery.amountCollectedInCake * viewLottery.treasuryFee /10000) * 1000000) / 1000000; 
          // 获取当前时间
          let time = parseInt(new Date().getTime() / 1000) + '';
          that.seconds = viewLottery.endTime - time;
          that.viewLottery = viewLottery;
          console.log(' viewLottery end ',viewLottery);
        })
        // 查看用户已购买彩票
        this.mLottery.viewUserInfoForLotteryId(lotteryId).then(res=>{
          console.log(res);
          that.currentLotteryUserHasTicketsNum = res[3];
        })
      })


    },
    // 天 时 分 秒 格式化函数
    countDown() {
        let d = parseInt(this.seconds / (24 * 60 * 60))
        d = d < 10 ? "0" + d : d
        let h = parseInt(this.seconds / (60 * 60) % 24);
        h = h < 10 ? "0" + h : h
        let m = parseInt(this.seconds / 60 % 60);
        m = m < 10 ? "0" + m : m
        let s = parseInt(this.seconds % 60);
        s = s < 10 ? "0" + s : s
        this.count = d + this.$t('menu.day') + h + ':' + m + ':' + s 
        if(this.seconds==0){
          clearTimeout(this.timeId)
        }
    }, 
    //定时器没过1秒参数减1
    Time() {
        setInterval(() => {
            this.seconds -= 1
            if(this.seconds<0){
              this.seconds = 0
            }
            this.countDown()
        }, 1000)
    },
    // 转换位usdt 
    conversionToUsdt(_amount){
      return Math.floor((_amount * this.price) * 1000000) / 1000000; 
    }
  }
}
</script>